<template>
	<div class="meeting_agree_login">
		<div class="logo">
			<img class="logo__inner" src="@/img/logo.png" alt="" />
			<div>
				<div class="page_title">手机号登录</div>
				<div class="page_desc">验证后将自动为您注册</div>
			</div>
		</div>

		<div class="form">
			<div class="input_control">
				<van-field
					v-model="params.mobile"
					placeholder="请输入手机号"
					maxlength="11"
				/>
			</div>
			<div class="input_control">
				<van-field
					v-model="params.vcode"
					placeholder="请输入验证码"
					maxlength="6"
				>
					<template #button>
            <van-count-down v-if="codeCountdown > 0" :time="codeCountdown" format="ss秒" @finish="countDownFinish"/>
						<div v-else class="send_code" @click="clickSendCode">发送验证码</div>
					</template>
				</van-field>
			</div>

			<div class="submit_box">
				<div class="agree">
					<van-checkbox v-model="isAgreeChecked" checked-color="#F4CD13"></van-checkbox>
					<span>我已阅读并同意</span>
					<span class="agree_inner" @click="clickShowFw">《服务协议》</span>
					<span>以及</span>
					<span class="agree_inner" @click="clickShowYs">《隐私政策》</span>
				</div>
				<van-button type="primary" block @click="clickLogin">立即登录</van-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MeetingAgreeLogin",
	data: () => ({
		params: {
			mobile: "",
			vcode: "",
			loginPlatform: 2,
			loginChannel: 0,
			role: 1,
		},
    codeCountdown: 0,
    isAgreeChecked: 0
	}),
	methods: {
    countDownFinish () {
      this.codeCountdown = 0
    },
		clickSendCode() {
      if (this.params.mobile.length !== 11) {
        return this.$toast("请输入正确的手机号");
      }
			this.$api.base
				.sendCode({
					mobile: this.params.mobile,
					role: 1,
					loginPlatform: 2,
				})
				.then((res) => {
					if (!res.code) {
						this.$toast("验证码已发送");
						this.codeCountdown = 10 * 1000;
					}
				});
		},
    loginVertify () {
      if (this.params.mobile.length !== 11) {
        this.$toast('请输入正确的手机号')
        return false
      }
      if (this.params.vcode.length !== 6) {
        this.$toast('请输入正确的验证码')
        return false
      }
      if (!this.isAgreeChecked) {
        this.$toast('请阅读并同意协议')
        return false
      }
      return true
    },
    clickLogin () {
      if (!this.loginVertify()) return
      this.$api.base.login(this.params).then(res => {
        if (!res.code) {
          this.$store.commit("user/SET_TOKEN", res.data.token);
          this.$store.commit("user/SET_LOGIN_INFO", res.data);
          this.$router.replace({
            path: '/meeting/agree/list',
            query: this.$route.query
          })
        }
      })
    },
    clickShowFw () {
      this.$router.push(`/agreement/fw`)
    },
    clickShowYs () {
      this.$router.push(`/agreement/ys`)
    },
    
	},
};
</script>

<style lang="less">
.meeting_agree_login {
	background-color: #fff;
	box-sizing: border-box;
	min-height: 100vh;
	padding: 50px 30px 0;
	.logo {
		text-align: center;
		&__inner {
			width: 150px;
			height: 70px;
		}
		.page_title {
			margin-top: 16px;
			font-family: PingFang SC;
			font-size: 24px;
			font-weight: 500;
			line-height: 34px;
			letter-spacing: 0px;
			color: #1f2329;
		}
		.page_desc {
			margin-top: 4px;
			font-family: PingFang SC;
			font-size: 13px;
			font-weight: normal;
			line-height: normal;
			color: #1f2329;
		}
	}

	.form {
		padding-top: 84px;
		.input_control {
			border-bottom: 1px solid #eff0f1;
			margin-bottom: 10px;
			.van-cell {
				padding: 14px 0;
				.van-field__body {
					font-family: PingFang SC;
					font-size: 18px;
					font-weight: normal;
					line-height: normal;
				}
			}

			.send_code {
				color: #1c2857;
        font-family: PingFang SC;
        font-size: 15px;
        font-weight: normal;
        line-height: normal;
        text-align: right;
			}
		}

		.submit_box {
			margin-top: 42px;
			.agree {
				display: flex;
				font-family: PingFang SC;
				font-size: 13px;
				font-weight: normal;
				line-height: normal;
				color: #8f959e;
				.agree_inner {
					color: #1c2857;
				}
				.van-checkbox {
					margin-right: 6px;
          .van-checkbox__icon--checked .van-icon {
            color: #000;
            font-weight: bold;
          }
				}
			}
			.van-button {
				margin-top: 18px;
				height: 48px;
				border-radius: 4px;
				.van-button__text {
					font-family: PingFang SC;
					font-size: 16px;
					font-weight: 500;
					line-height: normal;
					text-align: center;
					color: #000000;
				}
			}
		}
	}
}
</style>
